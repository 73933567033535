import React, { useEffect } from "react";
import Carousel from "./Carousel";
import styled from "styled-components";
import time from "../Utils/TimeFormat";
import { Link } from "gatsby";
import Image from "./ImageWithLoading";

import * as Theme from "../Theme";

/// 1509px × 875px
const RATIO = 875 / 1509;

export default function LandingCarousel(props) {
  const { width = "100%", height = "100%", items, rwd, style = {} } = props;

  if (items.length <= 0) {
    return (
      <Wrapper style={style} rwd={rwd} isRow={rwd === "desktop"}>
        <div style={{ flex: 1, width: "100%" }}>
          <ImageWrapper>
            <div
              style={{
                backgroundColor: "#f0f0f0",
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </ImageWrapper>
        </div>
        <div className="outside-title">
          <div
            style={{
              backgroundColor: "#f0f0f0",
              marginBottom: 10,
              width: 300,
              height: 40,
            }}
          />
          <div style={{ backgroundColor: "#f0f0f0", width: 50, height: 20 }} />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper style={style} rwd={rwd} isRow={rwd === "desktop"}>
      <Carousel
        width={width}
        height={height}
        data={items}
        renderPrev={null}
        renderNext={null}
        renderDots={({ currIdx, goToIndex }) => {
          return (
            <FlexRow rwd={rwd}>
              <div className="btns-wrapper">
                {/* <Arrow
                onClick={() => goToIndex(currIdx - 1)}
                style={{
                  width: size,
                  height: size,
                  borderLeft: "none",
                  userSelect: "none",
                }}
              >
                <div className="arrow-prev" />
              </Arrow> */}
                <Arrow
                  onClick={() =>
                    goToIndex(currIdx === items.length - 1 ? 0 : currIdx + 1)
                  }
                  style={{
                    width: props.rwd === "desktop" ? 60 : 32,
                    height: props.rwd === "desktop" ? 60 : 32,
                    userSelect: "none",
                    marginLeft: 20,
                  }}
                >
                  <div className="arrow-next" />
                </Arrow>
              </div>
            </FlexRow>
          );
        }}
        renderItem={({ item }) => (
          <Link to={`/article?id=${item.id}`} style={{ flex: 1 }}>
            <ImageWrapper>
              <Image src={item.image} alt="blog image" />
            </ImageWrapper>
          </Link>
        )}
        disableSwipe
        renderTitle={({ data, currIdx }) => {
          const item = data[currIdx] || {};
          return (
            <div className="outside-title">
              <h1>{item.title}</h1>
              <p style={{ whiteSpace: "break-spaces", margin: 0 }}>
                {`${item.label.slice(0, 1)}  |  ${time.format(item.created)}`}
              </p>
            </div>
          );
        }}
        style={{ flex: 1, width: "100%" }}
        autoplay={true}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.isRow ? "row" : "column")};
  align-items: ${props => (props.isRow ? "flex-end" : "flex-start")};

  & > .outside-title {
    // XXX: hot fix width 400px (320 + 40 + 40)px
    /* width: 320px; */
    flex-basis: ${props => (props.isRow ? "320px" : "auto")};
    /* width: ${props => (props.direction ? "320px" : "auto")}; */
    order: ${props => (props.isRow ? "-1" : "2")};
    margin-top: ${props => (props.isRow ? 0 : 30)}px;
    margin-right: ${props => (props.isRow ? 40 : 0)}px;

    & > h1 {
      margin-bottom: 15px;
      font-size: ${props => (props.rwd === "mobile" ? "24px" : "26px")};
      font-weight: normal;
      line-height: 40px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    animation: rollout 0.8s ease-out;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;

  & .padding {
    flex: 1;
  }
  & .btns-wrapper {
    position: absolute;
    display: flex;
    bottom: 20px;
    right: 30px;
  }
  ${props => props.css}
`;

const Arrow = styled.div`
  z-index: 1;
  position: relative;
  width: 120px;
  height: 20px;
  cursor: pointer;
  & > .arrow-next {
    background-size: contain;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/images/arrow.png");
  }
  & > .arrow-next:hover {
    background-image: url("/images/arrow-hover.png");
  }

  & > .arrow-prev {
    background-size: contain;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/images/arrow-reverse.png");
  }
  & > .arrow-prev:hover {
    background-image: url("/images/arrow-reverse-hover.png");
  }
`;

const ImageWrapper = styled.figure`
  position: relative;
  padding-bottom: ${RATIO * 100}%;
  overflow: hidden;
  margin-bottom: 0;

  & > img {
    position: absolute;
    top: 0;
    width: 100%;
    animation: zoomout 0.8s ease-out;
  }
`;
