import React, { useCallback, useRef } from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import * as Ant from "antd";
import * as Theme from "../Theme";
import { connect } from "react-redux";
import Constants from "../constants";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import useDimension from "../hooks/use-dimension";
import shuffle from "../Utils/Shuffle";
import ProductItem from "../Components/ProductItem";
import StaticImage from "../Components/StaticImage";
import Carousel from "../Components/LandingPage.carousel";
import ApiBlog from "../ApiBlog";
import time from "../Utils/TimeFormat";
import { Context } from "../AppContext";
import Button from "../Components/Button";
import PeriodCarousel from "../Components/LandingPage.PeriodCarousel";
const slugify = require("uslug");

const AboutItemArr = [
  { title: "預約諮詢", img: "/images/about/icon-1.png" },
  { title: "專人報價", img: "/images/about/icon-2.png" },
  { title: "審查稿件", img: "/images/about/icon-3.png" },
  { title: "印刷製作", img: "/images/about/icon-4.png" },
  { title: "印件交貨", img: "/images/about/icon-5.png" },
];

const DOCUMENT_NAME = "Article_Default";

function LandingPage(props) {
  const { rwd, dimension } = useDimension();
  // const [bannerDimension, setBannerDimension] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [articles, setArticles] = React.useState([]);
  const [works, setWorks] = React.useState([]);
  const workItemWidth = dimension.innerWidth > Theme.breakpoints.xs ? 416 : 300;
  const workItemHeight =
    dimension.innerWidth > Theme.breakpoints.xs ? 280 : 200;

  const api = useRef(new ApiBlog()).current;

  const app = React.useContext(Context);

  const getBlogs = React.useCallback(async () => {
    try {
      const filters = [
        {
          label: { $not: { $regex: "FAQ" } },
        },
        {
          label: { $not: { $regex: "draft" } },
        },
      ];
      if (props.pageContext.label) {
        filters.push({
          label: { $regex: props.pageContext.label },
        });
      }

      let resp = await api.getBlogs({
        documentName: DOCUMENT_NAME,
        $and: filters,
      });

      setArticles(resp);
    } catch (ex) {
      console.warn(ex);
    }
  }, [api]);

  React.useEffect(() => {
    async function getProducts() {
      try {
        let products = await app.actions.getProducts({
          category: "",
          sort: "",
        });
        setProducts(
          products.filter(p => app.state.productNames.indexOf(p.name) > -1)
        );
      } catch (ex) {}
    }

    async function getWorks() {
      try {
        let resp = await app.actions.getWorks({
          query: {},
          sorting: ["-created"],
          paging: { offset: 1, limit: 20 },
          projection: { variant: 0, content: 0 },
        });
        setWorks(resp.results);
      } catch (ex) {}
    }

    (async () => {
      app.actions.setLoading(true);
      await Promise.all([getBlogs(), getProducts(), getWorks()]);
      app.actions.setLoading(false);
    })();
  }, [app.state.productNames, getBlogs, app.actions]);

  const itemExtraCss = "";

  return (
    <Wrapper rwd={rwd}>
      <div
        style={{
          paddingLeft: rwd === "desktop" ? 40 : rwd === "pad" ? 60 : 20,
        }}
      >
        <Carousel
          rwd={rwd}
          items={articles
            .filter(article =>
              Array.isArray(article.label)
                ? article.label.indexOf("印件分享") >= 0
                : false
            )
            .slice(0, 5)}
        />
      </div>
      <Space size="xl" />

      <div className="center-content rwd-gap">
        <Section data-delighter>
          <h2>Shop</h2>

          <div className="products-wrapper">
            {app.state.loading ? (
              <Ant.Spin style={{ display: "block", margin: "0 auto" }} />
            ) : (
              products
                .filter(p => p.tag === "NEW")
                .slice(0, 8)
                .sort((a, b) => new Date(b.updated) - new Date(a.updated))
                .map((p, idx) => {
                  return (
                    <ProductItem
                      key={idx}
                      product={p}
                      link={`/product/${p.id}`}
                      extraCss={itemExtraCss}
                    />
                  );
                })
            )}

            {/* compensate blank item for ui: justfy-content: space-between */}
            {[0, 1, 2].map(e => (
              <ProductItem key={e} product={null} extraCss={"margin: 0px;"} />
            ))}
          </div>
          <Button
            style={{
              width: rwd === "desktop" ? 420 : 343,
            }}
            onClick={() => navigate("/products/")}
          >
            查看所有商品
          </Button>
        </Section>
      </div>

      <Space size="xl" />

      <div className="center-content rwd-gap">
        <Section data-delighter>
          <h2>Works</h2>
          <div
            style={{
              paddingTop: 20,
              paddingBottom: rwd === "desktop" ? 40 : 85,
              overflow: "auto",
              width: "100%",
            }}
          >
            <div className="work-items-wrapper" style={{}}>
              {works.length > 0 &&
                shuffle(works)
                  .slice(0, 3)
                  .map((item, idx) => {
                    const image =
                      item.files &&
                      item.files.length > 0 &&
                      item.files[0].expected_url;
                    const url = `/work/${item.id}`;
                    return (
                      <WorkItem
                        rwd={rwd}
                        width={workItemWidth}
                        height={workItemHeight}
                        key={idx}
                        img={encodeURI(image)}
                        href={url}
                        target="_blank"
                      >
                        <div className="cover">
                          <p>{item.title}</p>
                          <p>{item.subtitle}</p>
                        </div>
                        <div className="work-img" />
                        {rwd !== "desktop" && (
                          <>
                            <p style={{ paddingTop: 20 }}>{item.title}</p>
                            <p>{item.subtitle}</p>
                          </>
                        )}
                      </WorkItem>
                    );
                  })}
            </div>
          </div>

          <div className="readmore-row">
            <div className="img-wrapper">
              <div className="readmore" onClick={() => navigate("/works/")} />
            </div>
          </div>
        </Section>

        <Space size="xl" />
      </div>

      <div className="subscribe-banner" data-delighter>
        <PeriodCarousel />
      </div>

      <Space size="xl" />

      <div className="center-content rwd-gap">
        <Section
          style={{ alignItems: "stretch", maxWidth: 1098, margin: "0px auto" }}
          data-delighter
        >
          <h2>Sensations Blog</h2>
          {articles
            // .sort((a, b) => b.created - a.created)
            .slice(0, 5)
            .map((article, idx) => (
              <Link
                style={{ cursor: "pointer" }}
                className="article-item"
                to={`/article?id=${article.id}`}
                key={idx}
              >
                <div style={{ display: "flex", marginRight: 40 }}>
                  <p>
                    {Array.isArray(article.label)
                      ? article.label.slice(0, 1)
                      : "-"}
                  </p>
                  <span style={{ margin: "0 10px" }}>|</span>
                  <p>{time.format(article.created)}</p>
                </div>
                <p>{article.title}</p>
              </Link>
            ))}
          <Space />

          <div className="readmore-row">
            <div className="img-wrapper">
              <div
                className="readmore"
                onClick={() => navigate("/articles/")}
              />
            </div>
          </div>
        </Section>

        <Space size="xl" />

        <Section data-delighter>
          <h2>About Us</h2>
          <p
            style={{ marginBottom: 20 }}
          >{`印刷作為設計的延伸，是將設計的視覺與概念實體化的重要角色。
我們了解設計與印刷之間的緊密關係、理解客戶對於質感的追求。
致力於「印刷體驗」的實踐與各種印刷工藝效果的整合，以最高規格的態度面對每一件印刷作品。`}</p>
          <div
            className="row"
            style={{ flexDirection: rwd === "mobile" ? "column" : "row" }}
          >
            {AboutItemArr.map((item, idx) => (
              <div
                key={idx}
                style={{
                  display: "flex",
                  flexDirection: rwd === "mobile" ? "column" : "row",
                  alignItems: "center",
                }}
              >
                <AboutItem rwd={rwd}>
                  <div className="about-img-wrapper">
                    <img className="about-img" src={item.img} />
                  </div>
                  <p>{item.title}</p>
                </AboutItem>
                {idx !== AboutItemArr.length - 1 && (
                  <img
                    src={
                      rwd === "mobile"
                        ? "/images/about/arrow-down.png"
                        : "/images/about/arrow.png"
                    }
                    width={rwd === "mobile" ? "8" : "42"}
                    height={rwd === "mobile" ? "35" : "12"}
                    style={
                      rwd === "mobile"
                        ? { marginLeft: 90 }
                        : { marginBottom: 50 }
                    }
                  />
                )}
              </div>
            ))}
          </div>
          <Space size="lg" />

          <Button
            style={{
              width: rwd === "desktop" ? 420 : 343,
            }}
            onClick={() => navigate("/about/")}
          >
            查看所有感官服務
          </Button>
        </Section>
      </div>
      <Space size="xl" />
      {/* 訂閱banner */}
      {/* <a
        className="subscribe-banner"
        href="https://www.yottau.com.tw/teacher/1688"
        target="_blank"
        data-delighter
        rel="noreferrer"
      >
        <img
          src={`/images/landing-subscribe-${rwd}.jpg`}
          width="100%"
          height="auto"
        />
      </a>{" "} */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .banner {
  }
  & > .subscribe-banner {
    display: block;
  }
  & > .subscribe-banner.delighter {
    transform: translateY(50px);
    transition: all 0.6s ease-out;
    opacity: 0.1;
  }
  & > .subscribe-banner.delighter.started {
    transform: none;
    opacity: 1;
  }
  & .center-content {
    max-width: ${Theme.centerContentMaxWidth};
    margin: 0px auto;

    &.rwd-gap {
      padding-right: ${props =>
        props.rwd === "pad" ? 60 : props.rwd === "mobile" ? 20 : 0}px;
      padding-left: ${props =>
        props.rwd === "pad" ? 60 : props.rwd === "mobile" ? 20 : 0}px;
    }

    & h2 {
      text-align: center;
      font-size: 32px;
      font-weight: 300;
    }
    & p {
      ${Theme.textContentStyle};
      white-space: ${props =>
        props.rwd === "mobile" ? "initial" : "pre-wrap"};
      text-align: center;
    }
    & .article-item {
      display: flex;
      flex-direction: ${props => (props.rwd === "mobile" ? "column" : "row")};
      padding: 24px 0px;
      border-bottom: 0.6px solid #a3a3a3;
      :first-child {
        border-top: 0.6px solid #a3a3a3;
      }
      & p {
        margin: 0;
      }
    }
    & .row {
      display: flex;
      align-items: center;
      justify-content: center;
      & > img {
        // arrow
        margin-bottom: 45px;
      }
    }
    & .work-items-wrapper {
      display: flex;

      & > * {
        flex-shrink: 0;
      }
    }
    & .readmore-row {
      display: flex;
      justify-content: flex-end;
      align-self: stretch;
      & > .img-wrapper {
        position: relative;
        width: 120px;
        height: 20px;
        cursor: pointer;
        & > .readmore {
          background-size: contain;
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("../../images/viewall.png");
        }
        & > .readmore:hover {
          background-image: url("../../images/viewall-hover.png");
        }
      }
    }
    & .products-wrapper {
      padding-top: 40px;
      align-self: stretch;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.delighter {
    transform: translateY(50px);
    transition: all 0.8s ease-out;
    opacity: 0;
  }
  &.delighter.started {
    transform: none;
    opacity: 1;
  }
`;

const Space = styled.div`
  height: ${props =>
    ({
      xl: 90,
      lg: 45,
      md: 30,
      sm: 15,
    }[props.size] || 30)}px;
`;

const WorkItem = styled.a`
  position: relative;
  cursor: pointer;
  width: ${props => `${props.width}px` || "300px"};
  height: ${props => `${props.height}px` || "200px"};
  margin-left: ${props => (props.rwd === "mobile" ? "22px" : "40px")};
  :first-child {
    margin-left: 0px;
  }
  & > p {
    margin: 0px;
    color: ${Theme.colors.main};
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  & > .work-img {
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${props => `url(${props.img})`};
  }

  & > .cover {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-size: contain;
    & > p {
      color: white;
      font-size: 21px;
      margin: 0px;
      max-width: 280px;
    }
  }
  :hover {
    & > .cover {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: url("../../images/item-hover.png");
    }
  }
`;

const AboutItem = styled.div`
  display: flex;
  flex-direction: ${props => (props.rwd === "mobile" ? "row" : "column")};
  align-items: center;
  text-align: center;
  padding: ${props => (props.rwd === "mobile" ? "0px" : "10px")};
  & > p {
    margin-top: 13px;
    margin-left: ${props => (props.rwd === "mobile" ? "15px" : "0px")};
  }
  & > .about-img-wrapper {
    position: relative;
    width: ${props => (props.rwd === "desktop" ? "100px" : "80px")};
    height: ${props => (props.rwd === "desktop" ? "100px" : "80px")};
    & > .about-img {
      background-size: contain;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: ${props => `url(${props.img})`};
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(LandingPage);
